import {
    AdministrationRoute,
    FacilityGroupsRoute,
    FacilityRoute,
    JournalRoute,
    ModelsRoute,
    UsersRoute,
} from '@app/routes';

export const MenuItems = [
    { ...JournalRoute, icon: 'icons/menu:jobs', name: 'Журнал Работ' },
    { ...FacilityRoute, icon: 'icons/menu:objects', name: 'Объекты' },
    { ...FacilityGroupsRoute, icon: 'icons/menu:groups', name: 'Группировка' },
    { ...ModelsRoute, icon: 'icons/menu:models', name: 'База Моделей' },
    { ...UsersRoute, icon: 'icons/menu:users', name: 'Пользователи' },
    { ...AdministrationRoute, icon: 'icons/menu:administration', name: 'Администрирование' },
] as const;
