import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    Component,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
    selector: 'un-tooltip-component',
    template: '<div class="tooltip-content">{{text}}</div>',
    styles: [
        `
            .tooltip-content {
                background: #3c4257;
                color: #ffffff;
                padding: 10px;
                border-radius: 4px;
                white-space: nowrap;
                opacity: 0;
                transform: scale(0.8);
                transition: opacity 200ms ease-out, transform 200ms ease-out;
            }

            .tooltip-content.show {
                opacity: 1;
                transform: scale(1) translateX(5px);
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TooltipComponent {
    @Input() text = '';
}

@Directive({
    selector: '[unTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnDestroy {
    @Input() unTooltipTitle = '';

    private overlayRef: OverlayRef | null = null;

    constructor(
        private readonly overlay: Overlay,
        private readonly positionBuilder: OverlayPositionBuilder,
        private readonly elementRef: ElementRef,
    ) {}

    // private hideTooltip() {
    //     if (this.overlayRef) {
    //         const tooltipElement = this.overlayRef.overlayElement.querySelector('.tooltip-content') as HTMLElement;

    //         if (tooltipElement) {
    //             tooltipElement.classList.remove('show');
    //         }

    //         setTimeout(() => {
    //             this.overlayRef?.dispose();
    //             this.overlayRef = null;
    //         }, 200); // Время для завершения анимации
    //     }
    // }

    ngOnDestroy(): void {
        this.hide();
    }

    @HostListener('mouseenter')
    show() {
        if (!this.unTooltipTitle) return;

        const positionStrategy = this.positionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
            {
                originX: 'end',
                originY: 'center',
                overlayX: 'start',
                overlayY: 'center',
            },
        ]);

        this.overlayRef = this.overlay.create({ positionStrategy });

        const tooltipPortal = new ComponentPortal(TooltipComponent);
        const tooltipRef = this.overlayRef.attach(tooltipPortal);
        tooltipRef.instance.text = this.unTooltipTitle;

        setTimeout(() => {
            const tooltipElement = this.overlayRef?.overlayElement.querySelector('.tooltip-content') as HTMLElement;

            if (tooltipElement) {
                tooltipElement.classList.add('show');
            }
        });
    }

    @HostListener('mouseleave')
    hide() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
