<nz-header class="un-header">
  <p *ngIf="pageTitle$ | async as pageTitle; else breadcrumbs" class="un-header__title">
    {{ pageTitle }}
  </p>

  <nz-divider nzType="vertical"></nz-divider>
  <ng-template [ngIf]="showJobNotifications">
    <button #notificationsTrigger class="un-header__notifications" (click)="onToggleNotifications()">
      <span class="un-header__notifications-icon" nz-icon nzType="icons:package"></span>

      <nz-badge nzSize="small" [nzCount]="(total$ | async) || 0" [nzOffset]="[4, -5]">
        <span></span>
      </nz-badge>
    </button>
  </ng-template>
  <button class="un-header__profile" nz-dropdown nzTrigger="click" [nzDropdownMenu]="profileMenu">
    <un-user-avatar [size]="25" [user]="profileService.current$ | async"></un-user-avatar>
    <div class="un-header__user">
      <div class="un-header__name">{{ userName }}</div>
      <div class="un-header__role">{{ userPosition }}</div>
    </div>
    <span nz-icon nzType="icons/material:expand-more"></span>
  </button>

  <nz-dropdown-menu #profileMenu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item (click)="onClickProfile()">Профиль</li>
      <li nz-menu-item [nzDisabled]="profileService.pending$ | async" (click)="onLogout()">Выйти</li>
    </ul>
  </nz-dropdown-menu>
</nz-header>

<ng-template #breadcrumbs>
  <un-breadcrumbs></un-breadcrumbs>
</ng-template>

<ng-template #errorNotification let-event="data">
  <div class="event-notification__title">
    <span class="event-notification__icon" nz-icon nzType="warning" nzTheme="outline"></span>
    {{ event.uniodName }}
    <a target="_blank" nz-button nzType="link" [routerLink]="['facilities', event.uniodId, 'events']"
      ><span nz-icon nzType="link" nzTheme="outline"></span
    ></a>
  </div>
  <div class="event-notification__timestamp">
    <span class="button__icon_add" nz-icon nzType="icons/objects:clock" nzTheme="outline"></span>
    {{ event.datetime | date : 'dd.MM.YYYY HH:mm' }}
  </div>
  <div class="event-notification__message">{{ event.fullMsg }}</div>
</ng-template>
